import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100%;
  height: 40rem;
  background-color: var(--white);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow: hidden;

  // Contrataste Container
  .contrataste-container {
    width: 100%;
    max-width: 1270px;
    display: flex;

    flex-wrap: wrap;
    margin: 0px 0px 100px 0px;
    position: relative;
  }

  // Animación Container
  #animacion-container {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: var(--white);
    opacity: 0%;
    animation-name: animacion-completa;
    animation-duration: 1.8s;
    animation-iteration-count: initial;
    animation-timing-function: ease-out;
  }

  @keyframes animacion-completa {
    from {
      opacity: 100%;
    }
    95% {
      opacity: 100%;
    }
    to {
      opacity: 0%;
    }
  }

  // Animación Container Span
  #animacion-container span {
    width: 500px;
    height: 500px;
    position: absolute;
    border-radius: 40% 40% 100% 100%;
    background-color: #56c662;
    animation-name: circulo-verde;
    animation-duration: 2.1s;
    animation-iteration-count: initial;
    animation-timing-function: ease-in;
    z-index: 100;
  }

  @keyframes circulo-verde {
    from {
      width: 0px;
      height: 0px;
    }
    70% {
      width: 0px;
      height: 0px;
    }
    to {
      width: 1800px;
      height: 1800px;
    }
  }

  // Titulo
  h3 {
    width: 100%;
    margin: 0px 0px 0px 0px;
    text-align: center;
  }

  // Seguro Container
  #seguro-container {
    width: auto;
    max-height: 90px;
    margin: 64px 0px 0px 0px;
    padding: 16px 32px 16px 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    border-radius: 12px 12px 12px 12px;
    background-color: var(--blanco);
    border: 1px solid var(--grisClaro);
    box-shadow: 0px 5px 30px var(--sombraAzul);
  }

  // Seguro Container Imagen
  #seguro-container img {
    width: 88%;
    max-width: 145px;
    max-height: 30px;
    margin: 0px 16px 0px 0px;
    object-fit: contain;
  }

  // Seguro Container Texto Container
  #seguro-container div {
    width: fit-content;
    padding: 0px 0px 0px 24px;
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    border-left: 1px solid var(--grisClaro);
  }

  // Seguro Container Texto
  #seguro-container div p {
    width: auto;
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    font-size: 13px;
    color: var(--azul60);
    font-weight: 600;
    line-height: 24px;
  }

  // Seguro Container Texto Span
  #seguro-container div p span {
    width: auto;
    color: var(--negro);
    font-weight: 500;
  }

  // Texto Contacto
  #texto-contacto {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    justify-content: start;
    align-items: start;
    align-content: start;
    text-align: center !important;
    color: black;
    line-height: 32px;
    font-weight: 400;
    font-size: 28px;


  }

  // Texto Contacto Ícono
  #texto-contacto span {
    margin: 4px 7px 0px 0px;
    font-size: 22px;
  }

  // 1710px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1710}) {
    // Contrataste Container
    .contrataste-container {
      max-width: 1000px;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Contrataste Container
    .contrataste-container {
      max-width: 680px;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    // Contrataste Container
    .contrataste-container {
      max-width: 480px;
    }

    p {
      text-align: center;
    }

    // Texto Contacto Ícono
    #texto-contacto span {
      display: none;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    // Contrataste Container
    .contrataste-container {
      max-width: 350px;
    }

    // Seguro Container
    #seguro-container {
      max-height: 148px;
      margin: 64px 0px 0px 0px;
      padding: 24px 16px 24px 16px;
      flex-wrap: wrap;
    }

    // Seguro Container Imagen
    #seguro-container img {
      margin: 0px 0px 0px 0px;
    }

    // Seguro Container Texto Container
    #seguro-container div {
      width: 100%;
      padding: 16px 0px 0px 0px;
      justify-content: center;
      border-left: none;
    }

    // Seguro Container Texto
    #seguro-container div p {
      width: 100%;
      text-align: center !important;
      display: flex;
      justify-content: center;
    }

    // Texto Contacto
    #texto-contacto { 
      margin-top: 48px;
      font-size: 20px;
    }
    
    // Seguro Container Texto Span
    #seguro-container div p span {
      width: 100%;
      text-align: center;
    }
  }

  // 414px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile414}) {
    // Contrataste Container
    .contrataste-container {
      max-width: 85%;
    }
  }
`;

export default Container;
