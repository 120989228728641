import * as React from "react";
import { Fade } from "react-awesome-reveal";
import Container from "./styled";

export default function Marcas({ marcas }) {
  return (
    <Container marcas={marcas}>
      {/* Max Width Container */}
      {marcas == "active" && (
        <Fade
          delay={100}
          duration={700}
          triggerOnce={true}
          className="max-width-container"
        >
          {/* Titulo */}
          <h3 id="seguros-para-todo">Accedé a nuestra amplia variedad de seguros:</h3>
          {/* Texto */}
          <p id="parrafo-seguros-para-todo">
            ✅ <strong>Viví tranquilo y al mejor precio</strong> con <a href="https://www.segurohogar.com.ar/" target="_blank">SeguroHogar</a>.<br/>
            ✅ <strong> Protegé a tu fiel amigo </strong> con <a href="https://www.seguromascotas.com.ar/" target="_blank">SeguroMascotas</a>. <br/>
            ✅ <strong> Circulá acompañado por las coberturas más completas </strong> con <a href="https://www.seguromotos.com.ar/" target="_blank">SeguroMotos</a>. <br/>
            ➡ <strong> Asegurá tu patrimonio con los diversos seguros </strong> que <a href="https://www.seguroweb.com.ar/" target="_blank">SeguroWeb </a>tiene para vos! ⬅<br/>
            (Seguro de Auto, Seguro de Camión, Seguro Pickup, Seguro de Comercio, y muchos más!).
          </p>
          {/* Logos Container */}
          <div>
            {/* Seguro Logo */}
            <a href="https://www.seguromotos.com.ar/" target="_blank">
              <img
                alt="Logo Seguro Motos"
                src="/images/logos/seguromotos.svg"
                id="seguroMotos"
              ></img>
            </a>
            {/* Seguro Logo */}
            <a href="https://www.seguromascotas.com.ar//" target="_blank">
              <img
                alt="Logo Seguro Mascotas"
                src="/images/logos/seguromascotas.svg"
                id="seguroMascotas"
              ></img>
            </a>
            {/* Seguro Logo */}
            <a href="https://www.segurohogar.com.ar/" target="_blank">
              <img
                alt="Logo Seguro Hogar"
                src="/images/logos/segurohogar.svg"
                id="seguroHogar"
              ></img>
            </a>
            {/* Seguro Logo */}
            <a href="https://www.seguroweb.com.ar/" target="_blank">
              <img
                alt="Logo Seguro Web"
                src="/images/logos/seguroweb.svg"
                id="seguroWeb"
              ></img>
            </a>
          </div>
        </Fade>
      )}
    </Container>
  );
}
