import axios from "axios";

// URL API AUTOS
const urlAPI = "https://parallelum.com.br/fipe/api/v2";

export const obtenerMarcas = async (producto, setMarcas) => {
    try {
      if (producto === "camion") {
        const response = await axios.get(`${urlAPI}/trucks/brands`);
        setMarcas(response.data);
      } else {
        const response = await axios.get(`${urlAPI}/cars/brands`);
        setMarcas(response.data);
      }
      
    } catch (error) {
      console.error("Error al obtener las marcas:", error);
    }
  };

  export const obtenerModelos = async (marca, setModelos) => {
    try {
      const response = await axios.get(`${urlAPI}/cars/brands/${marca}/models`);
      setModelos(response.data);
    } catch (error) {
      console.error("Error al obtener los modelos:", error);
    }
  };

  export const obtenerAnos = async (marca, modelo, setAnos) => {
    try {
      const response = await axios.get(
        `${urlAPI}/cars/brands/${marca}/models/${modelo}/years`
      );

      const anosData = response.data;
  
      const anoActual = new Date().getFullYear();
  
      const anosList = anosData
        .map((ano) => parseInt(ano.name.match(/\d+/)[0])) // Obtengo años
        .filter((ano) => ( ano <= anoActual ))  // Filtro los anos por inconveniente en API
        .reduce((anos, ano) => { 
          if (!anos.includes(ano)) { // Saco posibles duplicados
            anos.push(ano);
          }
          return anos;
        }, []);
  
      const maxAno = Math.max(...anosList);
      const anosFinal = maxAno < anoActual ? [maxAno + 1, ...anosList] : anosList; // Agrego año actual
  
      setAnos(anosFinal);

    } catch (error) {
      console.error("Error al obtener los años:", error);
    }
  };