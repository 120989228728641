import React, { useEffect, useState } from "react";
import Marcador from "../../Marcador";
import { BsCheckLg } from "react-icons/bs";
import Container from "./styled";
import Sound from "react-sound";
import Animacion from "./animacion";
import Timbre from "./timbre.mp3";
import axios from "axios";
import url from "../../../utils/url";
import { useLocation } from "react-router-dom";

export default function Confirmacion({ planSelected, anioAuto }) {
  // Scroll ni bien carga la página

  const search = useLocation().search;
  const numOp = new URLSearchParams(search).get("op");

  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  useEffect(() => {
    mandarMailConsulta();
  }, []);

  async function mandarMailConsulta() {
    if (
      planSelected.seguro === "Federación Patronal Seguros" ||
      planSelected.seguro === "Experta Seguros" ||
      !planSelected.tienePrecio
    ) {
      await axios.post(`${url}/api/sendEmails/contratacion/${numOp}`, {
        compania: planSelected.seguro,
        plan: planSelected.plan,
      });
    }
  }

  // El useEffect sin dependencias ([]) se va a ejecutar al momento del render del componente.
  useEffect(() => {
    // Crear elemento "script" para insertar en el HTML
    const scriptPixel = document.createElement("script");

    // Asignar el contenido necesario al elemento script
    scriptPixel.text = `fbq('track', 'Lead', {})`;

    // Insertar script en el HTML
    document.body.appendChild(scriptPixel);

    // Remover script del HTML cuando se produzca el unmount el componente
    return () => {
      document.body.removeChild(scriptPixel);
    };
  }, []);

  useEffect(() => {
    // Crear elemento "script" para insertar en el HTML
    const scriptGoogleAds = document.createElement("script");

    // Asignar el contenido necesario al elemento script
    scriptGoogleAds.text = `gtag('event', 'conversion', {'send_to': 'AW-959485109/n3ZACOqEjNEDELWpwskD'})`;

    // Insertar script en el HTML
    document.head.appendChild(scriptGoogleAds);

    // Remover script del HTML cuando se produzca el unmount el componente
    return () => {
      document.head.removeChild(scriptGoogleAds);
    };
  }, []);

  return (
    <Container className="topInOpacity5">
      <Marcador
        pasoUno=""
        pasoDos=""
        pasoTres=""
        pasosTerminados={true}
        titulo={`¡Registramos tu consulta exitosamente, ${localStorage.getItem("nombre-seguroautos")}!`}
        pasoTresTexto="Consultá"
      ></Marcador>
      <Sound
        url={Timbre}
        autoLoad={true}
        playStatus={Sound.status.PLAYING}
        volume={80}
      />
      <div className="contrataste-container opacidad04">
        <div id="animacion-container">
          <span></span>
          <Animacion></Animacion>
        </div>
        <p id="texto-contacto">
              <bold>Un asesor comercial se pondrá en contacto con vos para ayudarte a encontrar y 
              contratar la mejor cotización para tu vehículo.</bold>
        </p>

      </div>
    </Container>
  );
}
