import { useState } from "react";
import styled from "styled-components";
import Header from "../components/header";
import Headroom from "react-headroom";
import StepsSwitch from "../components/steps/StepsSwitch";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  background-color: var(--blanco);
`;

export default function Steps() {

  // Estado para vehiculo
  const [vehiculo, setVehiculo] = useState(
    localStorage.getItem("producto-seguroautos") !== null
      ? localStorage.getItem("producto-seguroautos")
      : "auto"
  );

  const actualizarVehiculo = (e) => setVehiculo(e);
  
  return (
    <Container>
      {/* Header */}
      <Headroom>
        <Header vehiculo={vehiculo}></Header>
      </Headroom>
      {/* Switch de pasos*/}dfc
      <StepsSwitch actualizarVehiculo={actualizarVehiculo}></StepsSwitch>
    </Container>
  );
}
