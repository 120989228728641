
export const obtenerOp = () => {
    try {
        // Obtener el valor "op" de URL
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get("op");
        return id;
    } catch (error) {
        console.error(error);
    }
    

};