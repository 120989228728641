import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 50%;

  margin: 0px 0px 0px 0px;
  display: flex;
  justify-content: center;

  // Coberturas Container
  #coberturas-container {
    width: 100%;
    display: flex;
    flex-wrap: no-wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  // Titulo
  #coberturas-container h3 {
    margin: 64px 0px 60px 0px;
    text-align: center;
    font-size: 24px;
  }

  #coberturas-container h3 span {
    color: var(--azul100);
    white-space: nowrap;
  }

  #columnas-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
  }

  // Columna
  .columna {
    width: 33%;
    display: flex;
    justify-content: center;
    .tag-resumen {
      display: none;
    }
  }

  // Tag
  .tag {
    padding: 6px 20px 6px 20px;
    margin: 0px 6px 30px 6px;
    display: flex;
    align-items: center;
    align-content: center;
    background: var(--cielo);
    border-radius: 16px;
  }

  .tag:hover {
    background: #E7ECFD;
    // box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }

  }

  // Tag Texto
  .tag p {
    width: 180px;
    max-height: 32px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin: 0px 0px 0px 0px;
    font-weight: 700;
    color: var(--azul20);
  }

  // // Tag Icon
  .tag p span {
    margin: 5px 15px 0px 0px;
    font-size: 1.2em;
    color: var(--verde50);
  }

  // Line Break
  .line-break {
    width: 100%;
  }

  // Botón Coizar
  a {
    margin: 40px 0px 0px 0px;
    padding: 12px 24px 12px 24px;
    border-radius: 8px;
    color: var(--blanco);
    background-color: var(--azul60);
    cursor: pointer !important;
    transition: 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  // Botón Cotizar Hover
  a:hover {
    background-color: var(--azul0);
  }

  // 1710px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1710}) {
    #coberturas-container {
      width: 1100px;
    }
  }

  // 1367px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1366}) {
    #coberturas-container {
      max-width: 40%;
    }

    // Tag
    .tag {
      padding: 0px;
    }

    // Tag Icon
    .tag p {
      width: 140px;
      max-height: 32px;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      margin: 0px 0px 0px 0px;
    }
  }



  // 1440px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1440}) {

    .columna {
      .tag-resumen {
        display: flex;
      }
      .tag-completo {
        display: none;
      }
    }

    // Coberturas Container
    #coberturas-container {
      width: 680px;
    }

    // Tag
    .tag {
      padding: 3px 12px 3px 12px;
    }

  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Coberturas Container
    #coberturas-container {
      width: 680px;
    }

    // Tag
    .tag {
      padding: 5px 21px 5px 21px;
    }

  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    display: none;
  }
`;

export default Container;
