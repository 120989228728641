import React, { useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { StickyContainer, Sticky } from "react-sticky";
import { Link as Boton } from "react-router-dom";
import { BiError } from "react-icons/bi";
import axios from "axios";
import url from "../../../../utils/url";
import Container from "./styled";
import { BsWhatsapp } from "react-icons/bs";

export default function PasoUno({ pasoSiguiente }) {
  // Valores de los inputs
  const [inputValues, setInputValues] = useState({
    email:
      localStorage.getItem("email-seguroauto") !== null
        ? localStorage.getItem("email-seguroauto")
        : "",
  });

  // Errores del formulario
  const [formErrors, setFormErrors] = useState({});

  // Pop-up Errores
  const [popupErrores, setPopupErrores] = useState(false);

  // Toggle Pop-up Errores
  const togglePopupErrores = () => {
    setPopupErrores(!popupErrores);
  };

  // Setea el valor de los inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues((prev) => ({ ...prev, [name]: value }));
    // Guarda el valor de cada input en el localStorage con su nombre para autocompletar el paso 3
    localStorage.setItem([name], value + "-seguroauto");
  };

  // Captcha
  function onChange(value) {
    setInputValues((prev) => ({ ...prev, ["captcha"]: value }));
  }

  // Validar Formulario
  const validateForm = (e) => {
    e.preventDefault();
    let errors = {};

    // Validar correo electrónico
    if (!inputValues.email) {
      errors.email = "El correo electrónico es requerido";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputValues.email)) {
      errors.email = "El correo electrónico ingresado no es valido";
    }

    // Validar nombre del referido
    if (!inputValues.nombreReferido) {
      errors.nombreReferido = "El nombre del referido es requerido";
    } else if (inputValues.nombre.length < 2) {
      errors.nombreReferido =
        "El nombre del referido debe contener al menos 2 caracteres";
    } else if (
      !/^[a-zA-Z\u00C0-\u017F!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(
        inputValues.nombreReferido
      )
    ) {
      errors.nombreReferido = "El nombre del referido ingresado no es válido";
    }

    // Validar correo electrónico del referido
    if (!inputValues.emailReferido) {
      errors.emailReferido = "El correo electrónico del referido es requerido";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputValues.emailReferido)) {
      errors.emailReferido =
        "El correo electrónico ingresado del referido no es valido";
    }

    // Validar captcha
    if (inputValues.captcha == undefined) {
      errors.captcha = "Confirme la verificación reCaptcha";
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      sendMail();
      pasoSiguiente();
    } else if (popupErrores != true) {
      togglePopupErrores();
    }
  };

  // Enviar mail
  const sendMail = async () => {
    const mailContent = `
      <html><body>
        <h1>Referido SeguroAuto</h1>
        <h2>Datos del cliente/no cliente</h2>
        <p><b>Nombre:</b> ${inputValues.email}</p>
        <p><b>DNI:</b> ${inputValues.dni}</p>
        <p>Si el DNI llega vacio, la persona que envio el referido no es cliente</p>
        <h2>Datos del referido</h2>
        <p><b>Nombre:</b> ${inputValues.nombreReferido}</p>
        <p><b>Email:</b> ${inputValues.emailReferido}</p>
      </body></html>
      `;

    const mailData = {
      // mail: ["desarrollo.seguroweb@gmail.com"],
      subject: `Referido SeguroComercio`,
      htmlContent: mailContent,
    };

    const sendMail = await axios.post(
      `${url}/api/sendEmails?from=interno`,
      mailData
    );
  };

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Container>
      {/* Pop up Errores */}
      {popupErrores == true && (
        <div id="popup-error">
          {formErrors.email != null && (
            <p>
              <span>
                <BiError />
              </span>
              {formErrors.email}
            </p>
          )}
          {formErrors.nombreReferido != null && (
            <p>
              <span>
                <BiError />
              </span>
              {formErrors.nombreReferido}
            </p>
          )}
          {formErrors.emailReferido != null && (
            <p>
              <span>
                <BiError />
              </span>
              {formErrors.emailReferido}
            </p>
          )}
          {formErrors.captcha != null && (
            <p>
              <span>
                <BiError />
              </span>
              {formErrors.captcha}
            </p>
          )}
        </div>
      )}
      {/* Max Width Container */}
      <div id="max-width-container">
        <div id="formulario-container" className="opacidad05">
          <h3>¡Aprovechá nuestro programa de referidos!</h3>
          <p id="completa">
            ¿Sos cliente?:
            <br />
            ¡Referí y gana $4000! Contactate a nuestro{" "}
            <a href="https://api.whatsapp.com/send?phone=5491162255399&text=Hola%20%F0%9F%98%80%2C%20soy%20cliente%20y%20quiero%20referir!" target="_blank">
              <span>
                <BsWhatsapp />
              </span>
              {" "}WhatsApp
            </a>{" "}
            de Referidos
            <br />
            <br />
            ¿No sos cliente?:
            <br />
            ¡Tenés un 20% OFF en el seguro que quieras! Dejanos tu email para
            obtener este beneficio.
          </p>
          <form>
            <div id="datos-personales">
              <h4>Datos personales</h4>
              {/* Input Email */}
              <div className="input-container" id="input-email-container">
                <input
                  type="email"
                  id="input-email"
                  name="email"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.email}
                  className={formErrors.email == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.email == null || inputValues.email == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-email"
                >
                  Email
                </label>
              </div>
              {/* Input DNI */}
              <div className="input-container" id="input-dni-container">
                <input
                  type="number"
                  id="input-dni"
                  name="dni"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.dni}
                  className={formErrors.dni == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.dni == null || inputValues.dni == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-dni"
                >
                  DNI
                </label>
              </div>
              <p id="dni-texto">(DNI solo en caso de ser cliente)</p>
            </div>
            <div id="datos-referido">
              <h4>Datos del referido</h4>
              {/* Input Nombre Referido*/}
              <div
                className="input-container"
                id="input-nombre-referido-container"
              >
                <input
                  type="text"
                  id="input-nombre-referido"
                  name="nombreReferido"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.nombreReferido}
                  className={
                    formErrors.nombreReferido == null ? "" : "input-error"
                  }
                ></input>
                <label
                  className={
                    inputValues.nombreReferido == null ||
                    inputValues.nombreReferido == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-nombre-referido"
                >
                  Nombre
                </label>
              </div>
              {/* Input Email Referido */}
              <div
                className="input-container"
                id="input-email-referido-container"
              >
                <input
                  type="email"
                  id="input-email-referido"
                  name="emailReferido"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.emailReferido}
                  className={
                    formErrors.emailReferido == null ? "" : "input-error"
                  }
                ></input>
                <label
                  className={
                    inputValues.emailReferido == null ||
                    inputValues.emailReferido == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-email-referido"
                >
                  Email
                </label>
              </div>
            </div>
            <div id="captcha-container">
              <ReCAPTCHA
                sitekey="6LfvW5YdAAAAADsIry4KRJS5YkcRb7L20iJbYLzQ"
                size="compact"
                hl="es"
                theme="light"
                onChange={onChange}
              />
            </div>
            <div id="boton-container">
              <button type="submit" onClick={validateForm}>
                Enviar<span class="material-symbols-outlined">mail</span>
              </button>
            </div>
          </form>
        </div>
        <StickyContainer>
          <Sticky>
            {({ style }) => (
              <div id="plazos-container" className="opacidad05" style={style}>
                <p>Ver más</p>
                <Boton to="/contacto">Contacto</Boton>
                <Boton to="/sobre-nosotros">Sobre nosotros</Boton>
                <Boton to="/politicas-y-terminos">Políticas y términos</Boton>
                <Boton to="/forma-parte">Forma parte</Boton>
              </div>
            )}
          </Sticky>
        </StickyContainer>
      </div>
    </Container>
  );
}
