import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100%;
  margin: 64px 0px 64px 0px;
  background-color: var(--blanco);
  z-index: 1;

  // Max Width Container
  #max-width-container {
    width: 950px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  // Logo Slider
  #logo-slider {
    width: 200%;
    height: 49.3px;
    overflow: hidden;
    position: relative;
  }

  // Gradiente Izquierdo
  #gradiente-izq {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: flex-start;
    z-index: 99;
  }

  // Gradiente Derecho
  #gradiente-der {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    z-index: 99;
  }

  // Gradientes Imagenes
  #gradiente-der img,
  #gradiente-izq img {
    min-width: 100px;
    height: 49.3px;
    margin: 0px 0px 0px 0px;
    display: block;
  }

  // Gradiente Derecho Imagen
  #gradiente-der img {
    transform: rotate(180deg);
  }

  // Logos Container
  .logos-container {
    width: 200%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    position: absolute;
    left: 0;
    animation: logos 40s linear infinite;

    // Logo
    .logo {
      width: auto;
      margin: 0 56px 0px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
    }

    .logo img {
      width: 100%;
      pointer-events: none;
    }

    #prudenciaLogo {
      min-width: 140px !important;
    }

    #patronalLogo {
      min-width: 140px !important;
    }

    #provincialogo {
      min-width: 120px !important;
    }

    #expertalogo {
      min-width: 110px !important;
    }

    #hdiLogo {
      min-width: 80px !important;
    }

    #atmLogo {
      min-width: 85px !important;
    }

    #paranaLogo {
      min-width: 110px !important;
    }

    #nacionLogo {
      min-width: 190px !important;
    }

    #allianzlogo {
      min-width: 190px !important;
    }

        #sanCristoballogo {
      min-width: 190px !important;
    }
  }

  // Animación Logos
  @keyframes logos {
    0% {
      left: 0;
    }
    100% {
      left: -1423px;
    }
  }

  // 1710px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1710}) {
    margin: 24px 0px 24px 0px;
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Max Width Container
    #max-width-container {
      width: 680px;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    // Max Width Container
    #max-width-container {
      width: 480px;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    // Max Width Container
    #max-width-container {
      width: 350px;
    }
  }

  // 350px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile350}) {
    // Max Width Container
    #max-width-container {
      width: 85%;
    }
  }
`;

export default Container;
