import React, { useEffect } from "react";
import { Link } from "react-scroll";
import { StickyContainer, Sticky } from "react-sticky";
import Container from "./styled";
import { Link as Boton } from "react-router-dom";

export default function TextoSobreNosotros({ pagina }) {
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Container pagina={pagina}>
      {/* Max Width Container */}
      <div id="max-width-container">
        {pagina == "sobre-nosotros" ? (
          <div id="texto-container" className="opacidad05">
            {/* Titulo */}
            <h3 name="quienesSomos">¿Quiénes somos?</h3>
            <p>
              SeguroAuto es una marca registrada de la compañía Gestión y
              Servicios S.R.L., bróker de seguros multiproductos que se
              especializa en el asesoramiento y venta de seguros por internet.
              <br></br>Desde 1988, 28 años de experiencia respaldan nuestro
              rendimiento en el mercado de seguros. SeguroAuto comercializa
              seguros online por medio de un cotizador que permite comparar
              compañías y coberturas.
            </p>
            {/* Titulo */}
            <h3 name="nuestrosObjetivos">Nuestros objetivos</h3>
            <p>
              Mantener al cliente satisfecho a través de la mejor calidad de
              servicio, en cotización y contratación online de seguros, gestión
              de siniestros y atención personalizada.
              <br />
              ¿Cómo lo hacemos? Contamos con la experiencia y la eficiencia como
              pilares fundamentales, conjuntamente con un importante avance
              tecnológico, lo que avala la propuesta para que usted y sus
              clientes puedan sentirse plenamente respaldados.
            </p>
            {/* Titulo */}
            <h3 name="visionComercial">Visión comercial</h3>
            <p>
              Vivimos en un mundo en permanente cambio y SeguroAuto es la
              demostración de esto para nosotros. En un mercado tradicional como
              lo es el asegurador, el avance de la tecnología y las
              modificaciones del comportamiento de los consumidores han
              posibilitado la llegada de la venta de seguros en plataformas web.
              <br />
              SeguroAuto, pionero en la venta de seguros por internet, se
              posiciona hoy como líder de este canal, permitiendo constantemente
              la realización de nuevos seguros.
            </p>
            {/* Titulo */}
            <h3 name="queHemosLogrado">¿Qué hemos logrado?</h3>
            <p>
              Contamos con más de 32.000 pólizas vigentes.
              <br />
              Trabajamos con 23 compañías de seguros, incluyendo a las más
              importantes del país como Federación Patronal, ATM, Libra Seguros,
              Prudencia Seguros y Mapfre, entre muchísimas otras.
              <br />
              Hemos logrado desarrollar una gran comunidad por medio de nuestras
              redes sociales, como así también por nuestro blog y campañas de
              email marketing con más de 40.000 participantes.
            </p>
            {/* Titulo */}
            <h3 name="marcaLider">Marca líder</h3>
            <p>
              Somos una marca líder y pionera en seguros online, tenemos una
              vasta experiencia para satisfacer todas las expectativas que pueda
              tener.
              <br />
              Contamos con un departamento de atención al cliente para atender
              siniestros con el objeto de facilitarle todo el asesoramiento que
              necesite y acompañarlo en cuanta ocasión lo requiera, para
              acelerar cualquier trámite y que además reciba un servicio
              excelente de parte nuestra y de la compañia aseguradora.
            </p>
            {/* Titulo */}
            <h3 name="blogyRedes">Blog y redes sociales</h3>
            <p>
              Nuestro interés va más allá de reaccionar y servirle ante un
              siniestro (que es muy importante), pero a nosotros nos preocupa
              prioritariamente su seguridad.
              <br />
              También ponemos a disposición nuestra comunidad en las distintas
              redes sociales, como Facebook, Twitter, Youtube y LinkedIn con
              beneficios especiales, únicos y para participar, interactuar y
              divertirse en nuestras fan pages.
            </p>
            <h3 name="servicioDeExcelencia">Servicio de excelencia</h3>
            <p>
              Tenemos la convicción de que le brindaremos un servicio de
              excelencia.
              <br />
              SeguroWeb es la forma más fácil y rápida de cotizar un seguro de
              cualquier producto que necesites, comparar precios, coberturas y
              compañías, elegir y ahorrar en tus seguros.
            </p>
            {/* Titulo */}
            <h3 name="disposicion">Siempre a tu disposición</h3>
            <p>
              Somos una empresa líder en la venta de seguros por internet, donde
              no sólo facilitamos la cotización y contratación de las coberturas
              deseadas, sino que también estamos siempre a tu disposición, con
              atención telefónica de lunes a viernes de 9 a 20 hs. o de forma
              digital, ya sea por nuestras redes sociales, web o email.
            </p>
            {/* Titulo */}
            <h3 name="valores">Nuestros valores</h3>
            <p>
              Los valores que nos caracterizan y que cada integrante de nuestra
              empresa se compromete a llevar adelante desde el momento de su
              ingreso son:
              <br />
              ▸ Velar siempre por brindar la mejor calidad de servicio.
              <br />
              ▸ Buscar todos los medios para nunca decirles “no” a nuestros
              clientes.
              <br />
              ▸ Garantizar plena satisfacción a nuestros clientes. Trabajo en
              equipo e iniciativas individuales como motor para cumplir siempre
              en tiempo y forma.
              <br />
              ▸ La honestidad como base de nuestras virtudes.
              <br />
              ▸ Compromiso, responsabilidad y soluciones.
              <br />
              ▸ Mejora continua, tanto de nuestro servicio como de nuestros
              producos.
              <br />
              ▸ Adaptación a los cambios del entorno, costumbres y exigencias de
              los clientes, para estar siempre posicionados en un lugar de
              excelencia y satisfacción a los intereses sociales.
              <br />▸ Cultura ecológica, de respeto y contribución al medio
              ambiente.
            </p>
          </div>
        ) : (
          <div id="texto-container" className="opacidad05">
            {/* Titulo */}
            <h3 name="resguardar">
              Resguardar su privacidad es nuestra prioridad
            </h3>
            <p>
              No le solicitaremos, a menos que sea imprescindible, información
              personal. Jamás compartiremos su información personal con un
              tercero, salvo con la compañía aseguradora con la que usted decida
              realizar la póliza o en caso de protección de nuestros derechos.
              <br />
              Si en algún momento quisiera eliminar o corregir sus datos, puede
              hacerlo usted mismo desde cualquiera de nuestras vías de
              comunicación (mail a info@seguroweb.com.ar, teléfono 011 4328
              6757, o vía{" "}
              <a href="https://www.facebook.com/SeguroWeb/" target="_blank">
                Facebook
              </a>
              ).
              <br />
              Nos comprometemos a que su información será bien resguardada y
              ponemos a disposición la edición o eliminación de la base, cuando
              usted lo requiera.
            </p>
            {/* Titulo */}
            <h3 name="direccion-nacional">
              Dirección Nacional de Protección de Datos Personales
            </h3>
            <p>
              El titular de los datos personales tiene la facultad de ejercer el
              derecho de acceso a los mismos en forma gratuita y por intervalos
              no inferiores a seis meses, salvo que se acredite un interés
              legítimo al efecto, conforme lo establecido con el artículo 14,
              inciso 3, de la Ley Nº 25326.
              <br />
              La Dirección Nacional de Protección de Datos Personales, órgano de
              control de la Ley Nº 25.326, tiene la atribución de atender las
              denuncias y reclamos que se interpongan con relación al
              incumplimiento de las normas sobre protección de datos personales.
              <br />
              Para más información sobre: derechos de información, de acceso, de
              rectificación, actualización o supresión, información crediticia -
              derecho al olvido, puede ingresar a{" "}
              <a
                href="http://www.jus.gob.ar/datos-personales/tus-derechos.aspx"
                target="_blank"
              >
                tus derechos
              </a>
              .
            </p>
            {/* Titulo */}
            <h3 name="visitantes">Visitantes del sitio web</h3>
            <p>
              SeguroAuto toma información disponible desde los navegadores
              web y servidores, como la mayoría de los sitios con estadísticas.
            </p>
            {/* Titulo */}
            <h3 name="protegemos-datos-personales">
              Protegemos sus datos personales
            </h3>
            <p>
              SeguroAuto utilizará la información de contacto y la pondrá a
              disposición de sus empleados y/o de las compañías de seguro que
              necesiten obtener dicha información con el objeto de procesarla o
              de contactarlo. Al utilizar nuestro sitio, usted da permiso para
              que nuestros empleados y/o compañías de seguros emisoras de póliza
              puedan tener acceso a su información. SeguroAuto no
              compartirá, alquilará o venderá sus datos personales con nadie.
              SeguroAuto se reserva el derecho de revelar información
              personal en caso de citación, orden judicial o solicitud
              gubernamental aplicable.
              <br />
              Si usted nos proporciona datos registrándose en nuestro sitio
              facilitando su dirección de email, SeguroAuto puede enviarle
              correos electrónicos para brindarle información útil, servicios de
              newsletter, promociones y beneficios, solicitud de opiniones, etc.
              No nos gusta el SPAM, por eso, enviaremos sólo comunicaciones
              trascendentes o de interés consideradas por SeguroAuto.
              <br />
              SeguroAuto maximiza las medidas consideradas necesarias para
              protección contra accesos no autorizados, uso, alteración o
              destrucción de información personal.
            </p>
            {/* Titulo */}
            <h3 name="seguro-comercio">SeguroAuto</h3>
            <p>
              Recomendamos que lea los Términos de Uso del sitio
              auto.seguroweb.com.ar antes de su uso, ya que la utilización
              frecuente y continuada del mismo representará la aceptación de los
              términos. Estas condiciones del sitio auto.seguroweb.com.ar (de
              aquí en más, “el sitio”) así como las Políticas de Privacidad
              pueden ser modificadas por el mismo sin necesidad de aviso ni
              divulgación del hecho, por lo que es prudente que usted revise
              esta landing con asiduidad para estar al tanto de las
              actualizaciones. En caso de no coincidir con los términos de uso o
              con fragmentos de ellos, le recomendamos no continuar en el sitio.
            </p>
            {/* Titulo */}
            <h3 name="producto-ofrecido">Sobre el producto ofrecido</h3>
            <p>
              La información, valores y alcances de nuestros productos mostrados
              en el sitio están sujetos a posibles cambios relacionados con
              aéreas geográficas, casos extraordinarios y cuestiones personales
              del cliente. Los términos y condiciones informados en el sitio
              para cada producto de por SeguroComercial marca de Gestión y
              Servicios S.R.L. son a nivel general y puede que no todas las
              condiciones, aplicaciones, excepciones y beneficios estén
              plasmados.
              <br />
              Todo lo mostrado en el sitio está disponible en las jurisdicciones
              en las que está presente auto.seguroweb.com.ar con su
              correspondiente matrícula emitida por la Superintendencia de
              Seguros de la Nación.
              <br />
              Gestión y Servicios S.R.L. no garantiza que: el sitio
              auto.seguroweb.com.ar funcione ininterrumpidamente y en forma
              correcta ni que los errores visualizados sean corregidos, la
              navegación en el sitio auto.seguroweb.com.ar, el uso del servidor
              o los correos electrónicos enviados desde un dominio propio estén
              exentos de virus o software malicioso, el sitio
              auto.seguroweb.com.ar ofrezca productos o servicios que estén
              disponibles para usted o que las funciones del sitio sean
              utilizables por usted en la jurisdicción geográfica en que se
              encuentre.
              <br />
              Al realizar una cotización online en el sitio y dejar sus datos
              correspondientes a dirección de email o número telefónico, usted
              está aceptando unirse a nuestra base de datos y esa información
              personal puede utilizarse por SeguroComercial marca de Gestión y
              Servicios S.R.L. para fines comerciales. A la vez, SeguroBici
              marca de Gestión y Servicios S.R.L. se compromete a no usar esa
              información para otras finalidades y a no vender, intercambiar,
              ceder o hacer públicos esos datos.
            </p>
            {/* Titulo */}
            <h3 name="responsabilidad">Responsabilidad limitada</h3>
            <p>
              Auto.seguroweb.com.ar se desliga de toda responsabilidad ante
              lesiones, reclamos, daños o acciones surgidos de: el accionar del
              sitio, los materiales del sitio o la imposibilidad de acceso al
              mismo y lo mismo para sitios de terceros vinculados con
              auto.seguroweb.com.ar; errores, virus digitales, omisiones o
              fallas generadas desde el sitio, excepto negligencia grave y
              concreta de auto.seguroweb.com.ar.
            </p>
            {/* Titulo */}
            <h3 name="jurisdiccion">Jurisdicción</h3>
            <p>
              Los términos y condiciones aquí presentados están regidos por y
              siguen las leyes de la República Argentina y fueron redactados en
              base a lo establecido por la misma. Esto no implica que no pueda
              haber conflictos, desacuerdos, interpretaciones diferentes u
              opiniones variadas de estos términos, en cuyos casos el usuario
              deberá comunicarse de manera fehaciente con SeguroBici marca de
              Gestión y Servicios S.R.L. (Suipacha 245 6º piso, Capital Federal;
              teléfono: 011 4328 6757; dirección de email:{" "}
              <a href="mailto:info@gyssrl.com.ar">info@gyssrl.com.ar</a>). Si
              aún así persistiera el conflicto, tanto estos términos y
              condiciones como la compañía en sí están dispuestos a ser
              sometidos a los Tribunales correspondientes.
            </p>
          </div>
        )}
        {pagina == "sobre-nosotros" ? (
          <StickyContainer>
            <Sticky>
              {({ style }) => (
                <div id="nav-container" className="opacidad05" style={style}>
                  <p>Índice</p>
                  <Link
                    to="quienesSomos"
                    smooth={true}
                    spy={true}
                    duration={700}
                    offset={-100}
                  >
                    ¿Quíenes somos?
                  </Link>
                  <Link
                    to="nuestrosObjetivos"
                    smooth={true}
                    spy={true}
                    duration={700}
                    offset={-100}
                  >
                    Nuestros objetivos
                  </Link>
                  <Link
                    to="visionComercial"
                    smooth={true}
                    spy={true}
                    duration={700}
                    offset={-100}
                  >
                    Visión comercial
                  </Link>
                  <Link
                    to="queHemosLogrado"
                    smooth={true}
                    spy={true}
                    duration={700}
                    offset={-100}
                  >
                    ¿Qué hemos logrado?
                  </Link>
                  <Link
                    to="marcaLider"
                    smooth={true}
                    spy={true}
                    duration={700}
                    offset={-100}
                  >
                    Marca líder
                  </Link>
                  <Link
                    to="blogyRedes"
                    smooth={true}
                    spy={true}
                    duration={700}
                    offset={-100}
                  >
                    Blog y redes sociales
                  </Link>
                  <Link
                    to="servicioDeExcelencia"
                    smooth={true}
                    spy={true}
                    duration={700}
                    offset={-100}
                  >
                    Servicio de excelencia
                  </Link>
                  <Link
                    to="disposicion"
                    smooth={true}
                    spy={true}
                    duration={700}
                    offset={-100}
                  >
                    Siempre a tu disposición
                  </Link>
                  <Link
                    to="valores"
                    smooth={true}
                    spy={true}
                    duration={700}
                    offset={-100}
                  >
                    Nuestros valores
                  </Link>
                  <div>
                    <p>Ver más</p>
                    <Boton to="/politicas-y-terminos" onClick={goToTop}>
                      Políticas y términos
                    </Boton>
                    <Boton to="/contacto">Contacto</Boton>
                  </div>
                </div>
              )}
            </Sticky>
          </StickyContainer>
        ) : (
          <StickyContainer>
            <Sticky>
              {({ style }) => (
                <div id="nav-container" className="opacidad05" style={style}>
                  <p>Índice</p>
                  <Link
                    to="resguardar"
                    smooth={true}
                    spy={true}
                    duration={700}
                    offset={-100}
                  >
                    Resguardar su privacidad
                  </Link>
                  <Link
                    to="direccion-nacional"
                    smooth={true}
                    spy={true}
                    duration={700}
                    offset={-100}
                  >
                    Dir. Protección Datos Personales
                  </Link>
                  <Link
                    to="visitantes"
                    smooth={true}
                    spy={true}
                    duration={700}
                    offset={-100}
                  >
                    Visitantes del sitio web
                  </Link>
                  <Link
                    to="protegemos-datos-personales"
                    smooth={true}
                    spy={true}
                    duration={700}
                    offset={-100}
                  >
                    Protegemos sus datos personales
                  </Link>
                  <Link
                    to="seguro-comercio"
                    smooth={true}
                    spy={true}
                    duration={700}
                    offset={-100}
                  >
                    SeguroAuto
                  </Link>
                  <Link
                    to="producto-ofrecido"
                    smooth={true}
                    spy={true}
                    duration={700}
                    offset={-100}
                  >
                    Sobre el producto ofrecido
                  </Link>
                  <Link
                    to="responsabilidad"
                    smooth={true}
                    spy={true}
                    duration={700}
                    offset={-100}
                  >
                    Responsabilidad limitada
                  </Link>
                  <Link
                    to="jurisdiccion"
                    smooth={true}
                    spy={true}
                    duration={700}
                    offset={-100}
                  >
                    Jurisdicción
                  </Link>
                  <div>
                    <p>Ver más</p>
                    <Boton to="/sobre-nosotros" onClick={goToTop}>
                      Sobre nosotros
                    </Boton>
                    <Boton to="/contacto">Contacto</Boton>
                  </div>
                </div>
              )}
            </Sticky>
          </StickyContainer>
        )}
      </div>
    </Container>
  );
}
