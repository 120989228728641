import * as React from "react";
import Container from "./styled";

export default function Logos() {
  return (
    <Container>
      {/* Max Width Container */}
      <div id="max-width-container" className="opacidad08">
        {/* Logo Slider */}
        <div id="logo-slider">
          {/* Gradiente */}
          <div id="gradiente-izq">
            <img
              alt="Gradiente del slider"
              src="/images/slider/gradiente.png"
            ></img>
          </div>
          {/* Gradiente */}
          <div id="gradiente-der">
            <img
              alt="Gradiente del slider"
              src="/images/slider/gradiente.png"
            ></img>
          </div>

          {/* Logos Container */}
          <div className="logos-container">

            {/* Logo */}
            <div className="logo" id="prudenciaLogo">
              <img
                alt="Prudencia Seguros Logo"
                src="/images/logos/prudenciaseguros.webp"
              ></img>
            </div>
            {/* Logo */}
            <div className="logo" id="patronalLogo">
              <img
                alt="Federación Patronal Seguros S.A. Logo"
                src="/images/logos/federacionpatronalsegurosgrande.webp"
              ></img>
            </div>
            {/* Logo */}
            <div className="logo" id="provincialogo">
              <img
                alt="Provincia Seguros Logo"
                src="/images/logos/provincia-seguros.svg"
              ></img>
            </div>
            {/* Logo */}
            <div className="logo" id="allianzlogo">
              <img
                alt="Allianz Seguros Logo"
                src="/images/logos/allianz.svg"
              ></img>
            </div>
            {/* Logo */}
            <div className="logo" id="sanCristoballogo">
              <img
                alt="San Cristobal Logo"
                src="/images/logos/sanCristobal.svg"
              ></img>
            </div>
            {/* Logo */}
            <div className="logo" id="expertalogo">
              <img
                alt="Experta Seguros Logo"
                src="/images/logos/experta-seguros.svg"
              ></img>
            </div>
            {/* Logo */}
            <div className="logo" id="hdiLogo">
              <img alt="HDI Logo" src="/images/logos/hdiSeguros.svg"></img>
            </div>
            {/* Logo */}
            <div className="logo" id="atmLogo">
              <img
                alt="ATM Seguros Logo"
                src="/images/logos/atmseguros.svg"
              ></img>
            </div>
            {/* Logo */}
            <div className="logo" id="paranaLogo">
              <img
                alt="Parana Seguros Logo"
                src="/images/logos/paranaSeguros.svg"
              ></img>
            </div>
            {/* Logo */}
            <div className="logo" id="nacionLogo">
              <img
                alt="Nacion Seguros Logo"
                src="/images/logos/nacion-seguros.svg"
              ></img>
            </div>
            {/* Logo */}
            <div className="logo" id="prudenciaLogo">
              <img
                alt="Prudencia Seguros Logo"
                src="/images/logos/prudenciaseguros.webp"
              ></img>
            </div>
            {/* Logo */}
            <div className="logo" id="patronalLogo">
              <img
                alt="Federación Patronal Seguros S.A. Logo"
                src="/images/logos/federacionpatronalsegurosgrande.webp"
              ></img>
            </div>
            {/* Logo */}
            <div className="logo" id="provincialogo">
              <img
                alt="Provincia Seguros Logo"
                src="/images/logos/provincia-seguros.svg"
              ></img>
            </div>
            {/* Logo */}
            <div className="logo" id="allianzlogo">
              <img
                alt="Allianz Seguros Logo"
                src="/images/logos/allianz.svg"
              ></img>
            </div>
            {/* Logo */}
            <div className="logo" id="sanCristoballogo">
              <img
                alt="San Cristobal Logo"
                src="/images/logos/sanCristobal.svg"
              ></img>
            </div>
            {/* Logo */}
            <div className="logo" id="expertalogo">
              <img
                alt="Experta Seguros Logo"
                src="/images/logos/experta-seguros.svg"
              ></img>
            </div>
            {/* Logo */}
            <div className="logo" id="hdiLogo">
              <img alt="HDI Logo" src="/images/logos/hdiSeguros.svg"></img>
            </div>
            {/* Logo */}
            <div className="logo" id="atmLogo">
              <img
                alt="ATM Seguros Logo"
                src="/images/logos/atmseguros.svg"
              ></img>
            </div>
            {/* Logo */}
            <div className="logo" id="paranaLogo">
              <img
                alt="Parana Seguros Logo"
                src="/images/logos/paranaSeguros.svg"
              ></img>
            </div>
            {/* Logo */}
            <div className="logo" id="nacionLogo">
              <img
                alt="Nacion Seguros Logo"
                src="/images/logos/nacion-seguros.svg"
              ></img>
            </div>

          </div>
        </div>
      </div>
    </Container>
  );
}
