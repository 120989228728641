import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100% !important;
  padding: ${(props) =>
    props.marcas == "active" ? "40px 0px 0px 0px" : "0px 0px 0px 0px"};
  border-top: ${(props) =>
    props.marcas == "active" ? "1px solid var(--grisClaro);" : "0px"};
  border-bottom: 1px solid var(--grisClaro);
  background-color: var(--blanco);
  z-index: 1;
  content: "";

  // Max Width Container
  .max-width-container {
    max-width: 1270px;
    margin: 0 auto;
  }

  .max-width-container:last-child {
    padding: 0px 0px 100px 0px;
  }

  // Titulo
  div h3 {
    width: 100%;
    text-align: left;
    color: var(--azul60);
  }

  // Texto
  div p {
    width: 100%;
    max-width: 950px;
    margin: 40px 0px 0px 0px;
    text-align: left;
    line-height: 32px;
  }

  // Logos Container
  div div {
    width: 100%;
    margin: 64px 0px 0px 0px;
    display: flex;
    align-items: flex-end;
    align-content: flex-end;
    justify-content: flex-start;
  }

  // Seguro Logo Botón
  div div a {
    margin: 0px 40px 0px 0px;
    transition: 0.2s all;
  }

  // Seguro Logo Imagen
  div a img {
    width: auto;
    height: 32px;
  }

  // Seguro Logo Hover
  div div a:hover {
    cursor: pointer;
  }

  // 1710px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1710}) {
    // Max Width Container
    .max-width-container {
      max-width: 1000px;
    }

    // Seguro Logo Botón
    div div a {
      margin: 0px 24px 0px 0px;
    }

    // Seguro Logo Imagen
    div a img {
      height: 30px;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Max Width Container
    .max-width-container {
      max-width: 680px;
    }

    // Seguro Images Container
    div div {
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
      margin: 30px 0px 0px 0px;
    }

    // Seguro Logo
    div div a {
      width: 33.3%;
      margin: 40px 0px 0px 0px;
    }

    div div a img {
      display: block;
      margin: 0 auto;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    // Max Width Container
    .max-width-container {
      max-width: 480px;
    }

    // Seguro Logo
    div div a {
      width: 50%;
      margin: 40px 0px 0px 0px;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    // Max Width Container
    .max-width-container {
      max-width: 350px;
    }

    // Title
    div h3 {
      margin: 80px 0px 0px 0px;
    }

    // Seguro Logo
    div div a {
      width: 100%;
      margin: 45px 0px 0px 0px;
    }
  }

  // 450px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile450}) {
    // Max Width Container
    .max-width-container {
      max-width: 85%;
      margin: 0 auto;
    }

    // Seguro Logo
    div div {
      width: 100%;
      margin: 0 auto !important;
    }
  }
`;

export default Container;
