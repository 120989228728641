import styled from "styled-components";
import { breakpoints } from "../../../../constants/media";

const Container = styled.div`
  width: 100%;
  margin: 0px 0px 0px 0px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  // Dropdown Abierto y Dropdown Cerrado
  .dropdown-abierto,
  .dropdown-cerrado {
    width: 100%;
    max-width: 1078px;
    max-height: 115px;
    margin: 0 auto;
    padding: 24px 24px 24px 24px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    pointer-events: none;
    border: 0px;
    background-color: var(--blanco);
    border-radius: 16px !important;
  }

  // Logo y Plan Container
  .logo-plan-container {
    width: 100%;
    height: 67px;
    max-width: 115px;
    min-height: 67px;
    padding: 0px 24px 0px 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    border-right: 1px solid var(--grisClaro);
  }

  // Logo del Seguro
  .logo-plan-container img {
    width: 100%;
    max-height: 30px;
    height: auto;
    object-fit: contain;
  }

  // Logo HDI
  #logo-hdi {
    max-height: 25px !important;
  }

  // Logo Parana
  #logo-parana {
    max-height: 35px !important;
  }

  // Plan del Seguro
  .logo-plan-container p {
    margin: 10px 0px 0px 0px;
    font-weight: 600;
    font-size: 13px;
    text-align: center;
    color: var(--azul60);
  }

  // Checks Container
  .checks-container {
    width: 100%;
    max-width: 750px;
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
  }

  // Checks Container Icono
  .checks-container span {
    width: 100%;
    max-width: 120px;
    font-size: 24px;
    text-align: center;
    color: var(--verde50);

    .material-symbols-outlined {
      font-variation-settings: "FILL" 0, "wght" 700, "GRAD" 0, "opsz" 40;
    }
  }

  // X Icono
  #check-no {
    font-size: 22px;
    color: var(--rojo) !important;
  }

  // Precio Container
  .precio-container {
    width: 100%;
    max-width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: row;
  }

  // Informacion de precios
  .precio-container div {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  // Informacion de precios text
  .precio-container div p {
    margin: 0px 3px 0px 3px;
  }

  // Cuotas
  .precio-container div:nth-child(1) p {
    margin: 0px 0px 0px 0px;
    font-weight: bold;
    line-height: normal;
    font-size: 12px !important;
    text-align: center;
    color: var(--negro);

    #cuotas-bold {
      font-weight: 600 !important;
    }
  }

  // Precio anterior
  #precio-anterior {
    text-decoration: line-through !important;
  }

  .precio-container div:nth-child(2) p {
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    color: var(--azul60);
    margin: 6px 3px 6px 3px !important;
  }

  // Contratando
  .precio-container div:nth-child(3) p {
    font-weight: 600;
    font-size: 10px;
    text-align: center;
    color: var(--azul60);
  }

  // Botones Container
  .botones-container {
    width: 100%;
    max-width: 110px;
    padding: 0px 0px 0px 30px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
  }

  #contrata-boton {
    width: 100%;
    padding: 8px 24px 8px 24px;
    background-color: var(--azul60);
    color: var(--blanco);
    text-align: center;
    font-weight: 600;
    font-size: 13px;
    border: 0;
    border-radius: 8px;
    pointer-events: all;
    cursor: pointer;
    transition: 0.2s all;
  }

  #contrata-boton:hover {
    background-color: var(--azul20);
  }

  #ver-mas-boton {
    border: 0px;
    cursor: pointer;
    margin: 12px 0px 0px 0px;
    background-color: transparent;
    font-size: 12px;
    text-align: center;
    color: var(--azul60);
    pointer-events: all;
    transition: 0.2s all;
  }

  #ver-mas-boton:hover {
    color: var(--negro);
  }

  // Text Container
  .children-container {
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    align-content: center;
  }

  // Tiempo de transicion cuando se abre y se cierra
  .ReactCollapse--collapse {
    transition: height 550ms;
  }

  // 1710px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1710}) {
    // Dropdown Abierto y Dropdown Cerrado
    .dropdown-abierto,
    .dropdown-cerrado {
      max-width: 1018px;
    }

    // Checks Container
    .checks-container {
      max-width: 690px;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    .dropdown-abierto,
    .dropdown-cerrado {
      max-width: 328px !important;
    }

    // Checks Container
    .checks-container {
      display: none;
    }
  }

  // 350px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile350}) {
    .dropdown-abierto,
    .dropdown-cerrado {
      max-width: 100%;
      max-height: 182px;
    }

    // Logo y Plan Container
    .logo-plan-container {
      max-width: 100%;
      padding: 0px 0px 0px 0px;
      border: 0px;
    }

    // Botones Container
    .botones-container {
      max-width: 100%;
      padding: 30px 0px 0px 0px;
    }

    #contrata-boton {
      width: 51%;
    }

    #ver-mas-boton {
      width: 51%;
    }
  }
`;

export default Container;
