//Cambio de logos dependiendo seleccion

import React from "react";

export const Logos = ({ vehiculo }) => {

  return (
    <img alt={`Logo de Seguro ${vehiculo ? vehiculo : "auto"}`} src={`/images/logos/seguro-${vehiculo ? vehiculo : "auto"}.svg`}></img>
  );
  
};
