import React, { useState, useEffect } from "react";
import { Container } from "./styled";
import valid from "card-validator";

const Tarjeta = ({ titular, vencimiento, numero, focused }) => {
  const [brand, setBrand] = useState("");
  const [formattedNumber, setFormattedNumber] = useState("••••••••••••••••");
  const [formattedExpirationDate, setFormattedExpirationDate] =
    useState("••/••");

  // Info de las tarjetas
  const cardsData = {
    visa: {
      image: "/images/logos/tarjeta/visa-component.svg",
    },
    maestro: {
      image: "/images/logos/tarjeta/maestro-component.svg",
    },
    mastercard: {
      image: "/images/logos/tarjeta/mastercard-component.svg",
    },
    americanexpress: {
      image: "/images/logos/tarjeta/americanexpress-component.svg",
    },
    dinersclub: {
      image: "/images/logos/tarjeta/diners-component.svg",
    },
    naranja: {
      image: "/images/logos/tarjeta/naranja-component.svg",
    },
    cabal: {
      image: "/images/logos/tarjeta/cabal-component.jpg",
    },
    discover: {
      image: "/images/logos/tarjeta/discover-component.svg",
    },
    unionpay: {
      image: "/images/logos/tarjeta/unionpay-component.svg",
    },
    jcb: {
      image: "/images/logos/tarjeta/jcb-component.svg",
    },
    elo: {
      image: "/images/logos/tarjeta/elo-component.svg",
    },
    hipercard: {
      image: "/images/logos/tarjeta/hipercard-component.svg",
    },
    mir: {
      image: "/images/logos/tarjeta/mir-component.svg",
    },
    hiper: {
      image: "/images/logos/tarjeta/hiper-component.svg",
    },
  };

  // Número
  useEffect(() => {
    if (numero) {
      // Seleccionar tipo de tarjeta, espacios entre los numeros y cantidad de números.
      const selectBrand = valid.number(numero);
      const gaps = selectBrand.card ? selectBrand.card.gaps : [4, 8, 12];
      const creditCardLength =
        selectBrand.card === null || brand === "Naranja"
          ? 16
          : selectBrand.card.lengths[0];
      if (numero.length <= creditCardLength) {
        const numbers = numero;
        const limit = creditCardLength - numero.length;
        let dots = "•";
        for (let i = 1; i < limit; i++) {
          dots = dots + "•";
        }
        const fullNumbers = limit > 0 ? numbers + dots : numbers;
        let formatCreditCard = fullNumbers;
        if (gaps) {
          gaps.forEach((gap, index) => {
            if (gap === gaps[0]) {
              let partOne = formatCreditCard.substring(0, gap);
              let partTwo = formatCreditCard.substring(gap);
              formatCreditCard = partOne + " " + partTwo;
            } else {
              let partOne = formatCreditCard.substring(0, gap + index);
              let partTwo = formatCreditCard.substring(gap + index);
              formatCreditCard = partOne + " " + partTwo;
            }
          });
        }
        setFormattedNumber(formatCreditCard);
      }

      if (selectBrand.card !== null) {
        setBrand(selectBrand.card.niceType);
      }
      if (numero.startsWith(5895)) {
        setBrand("Naranja");
      }
      if (numero.startsWith(6042)) {
        setBrand("Cabal");
      }
    } else {
      // Resetear
      setFormattedNumber("•••• •••• •••• ••••");
      setBrand("");
    }
  }, [numero]);

  // Vencimiento
  useEffect(() => {
    if (vencimiento) {
      // Seleccionar tipo de tarjeta, espacios entre los numeros y cantidad de números.
      const expirationDateLength = 5;
      if (vencimiento.length <= expirationDateLength) {
        const expirationDate = vencimiento;
        const limit = expirationDateLength - vencimiento.length;
        let dots = "•";
        for (let i = 1; i < limit; i++) {
          dots = dots + "•";
        }
        const fullExpirationDate =
          limit > 0 ? expirationDate + dots : expirationDate;
        setFormattedExpirationDate(fullExpirationDate);
      }
    } else {
      // Resetear
      setFormattedExpirationDate("••/••");
    }
  }, [vencimiento]);

  return (
    <div>
      <Container
        brand={brand.replace(/\s/g, "").toLowerCase()}
        focused={focused}
      >
        {/* Logos y Chip */}
        <div id="logos-tarjeta">
          {/* Chip */}
          <img
            className="chip"
            src="/images/logos/tarjeta/chip.png"
            alt="chip"
          />
          {/* Logo Marca */}
          {brand && (
            <img
              class="logo-marca"
              src={cardsData[brand.replace(/\s/g, "").toLowerCase()].image}
              alt="logo"
            />
          )}
        </div>
        {/* Datos Tarjeta */}
        <div className="datos-tarjeta">
          {/* Número Tarjeta */}
          <div className="numero-tarjeta" id={focused == "number" && "active"}>
            {formattedNumber}
          </div>
          {/* Nombre y Vencimiento */}
          <div className="datos-tarjeta-nombre-vencimiento">
            {/* Nombre */}
            <div className="nombre-tarjeta">
              <p id="texto">Nombre</p>
              <p id={focused == "name" && "active"}>
                {titular == "" ? "Titular de la tarjeta" : titular}
              </p>
            </div>
            {/* Vencimiento */}
            <div className="vencimiento-tarjeta">
              <p id="texto">Vencimiento</p>
              <p id={focused == "expiry" && "active"}>
                {vencimiento == "" ? "••/••" : vencimiento}
              </p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Tarjeta;
