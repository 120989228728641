import React, { useEffect, useState } from "react";
import Container from "./styled.js";
import Marcador from "../../Marcador";
import { useBack } from "use-back";
import { BsArrowLeft } from "react-icons/bs";
import { IoMdArrowDropdown } from "react-icons/io";
import url from "../../../utils/url.js";
import { obtenerOp } from "./helpers/ObtenerOp.js";
import {
  obtenerAnos,
  obtenerMarcas,
  obtenerModelos,
} from "./helpers/ApiInfoAutos.js";
import axios from "axios";

export const CompletaInfo = ({ nextStep }) => {
  const [marcas, setMarcas] = useState([]);
  const [modelos, setModelos] = useState([]);
  const [anos, setAnos] = useState([]);

  const [inputValues, setInputValues] = useState({
    producto: "",
    marca: "",
    modelo: "",
    version: "",
    ano: "",
    suma_asegurada: "",
  });

  useEffect(() => {
    obtenerMarcas(inputValues.producto, setMarcas);
    setModelos([]);
    setAnos([]);
  }, [inputValues.producto]);

  const { hasBack, handleBack } = useBack();

  // Errores del formulario
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    // Obtener el valor "op" de URL
    const id = obtenerOp();

    setInputValues({
      ...inputValues,
      id: id,
    });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Obtener el valor "op" de URL
    const id = obtenerOp();

    setInputValues({
      ...inputValues,
      id: id,
    });

    try {
      const post = await axios.post(
        `${url}/api/infoAutosCotizados/datosVehiculo`,
        inputValues
      );
      if (post.status === 200) {
        nextStep();
      }
    } catch (error) {
      console.log(error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.yaExiste
      ) {
        const errorMessageElement = document.getElementById("errorMessage");
        errorMessageElement.textContent =
          "¡Ya estás registrado en nuestra base de datos! Por favor, espera a ser contactado por uno de nuestros agentes.";
      }
    }
  };

  return (
    <Container>
      <Marcador
        pasoUno="hecho"
        pasoDos="haciendo"
        pasoTres=""
        pasoTresTexto=""
        pasosTerminados=""
        titulo="¡Completa los datos de tu vehículo!"
      ></Marcador>

      <div className="boton-atras-container opacidad03">
        <button type="button" onClick={handleBack}>
          <span>
            <BsArrowLeft />
          </span>
          Volvé a cotizar
        </button>
      </div>
     
      {/* Formulario */}
      <div id="form-container" className="opacidad05">
        <h4>Datos de vehículo:</h4>
        <form onSubmit={handleSubmit}>
          <div id="inputs-container">
              <div className="select-container" id="input-big-container campo">
                <label
                   className={
                    inputValues.producto == null || inputValues.producto == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-producto"
                >* Tipo Vehículo</label>
                <div className="flecha-select-container">
                  <i></i>
                </div>
                <select
                  name="producto"
                  value={inputValues.producto}
                  onChange={handleInputChange}
                  required
                >
                  <option value=""></option>
                  <option value="auto">Auto</option>
                  <option value="camion">Camion</option>
                  <option value="pickup">Pickup</option>
                </select>
              </div>
              <div className="input-container" id="input-big-container campo">
              <label
                   className={
                    inputValues.suma_asegurada == null || inputValues.suma_asegurada == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-suma"
                >Suma asegurada $</label>
                <input
                  type="number"
                  name="suma_asegurada"
                  value={inputValues.suma_asegurada}
                  onChange={handleInputChange}
                />
              </div>
              <div className="select-container" id="input-big-container campo">
                <label
                   className={
                    inputValues.marcaCodigo == null || inputValues.marcaCodigo == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-marcaCodigo"
                >Marca</label>
                <div className="flecha-select-container">
                  <i></i>
                </div>
                <select
                  name="marcaCodigo"
                  value={inputValues.marcaCodigo}
                  onChange={(e) => {
                    handleInputChange(e);
                    const selectedMarca = marcas.find(
                      (marca) => marca.code === e.target.value
                    );
                    if (selectedMarca) {
                      setInputValues({
                        ...inputValues,
                        marcaCodigo: selectedMarca.code,
                        marca: selectedMarca.name,
                      });
                      obtenerModelos(selectedMarca.code, setModelos);
                    }
                  }}
                >
                  <option value=""></option>
                  {marcas.map((marca) => (
                    <option key={marca.code} value={marca.code}>
                      {marca.name}
                    </option>
                  ))}
                  <option value="9999">Otro</option>
                </select>
              </div>

              <div className="select-container" id="input-big-container campo">
                <label
                   className={
                    inputValues.modeloCodigo == null || inputValues.modeloCodigo == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-modeloCodigo"
                >Modelo</label>
                <div className="flecha-select-container">
                  <i></i>
                </div>
                <select
                  name="modeloCodigo"
                  value={inputValues.modeloCodigo}
                  onChange={(e) => {
                    handleInputChange(e);
                    const selectedModelo = modelos.find(
                      (modelo) => modelo.code === e.target.value
                    );
                    if (selectedModelo) {
                      setInputValues({
                        ...inputValues,
                        modeloCodigo: selectedModelo.code,
                        modelo: selectedModelo.name,
                      });
                      obtenerAnos(
                        inputValues.marcaCodigo,
                        selectedModelo.code,
                        setAnos
                      );
                    }
                  }}
                >
                  <option value=""></option>
                  {modelos.map((modelo) => (
                    <option key={modelo.code} value={modelo.code}>
                      {modelo.name}
                    </option>
                  ))}
                  <option value="9999">Otro</option>
                </select>
              </div>

              <div className="input-container" id="input-big-container campo">
                <label
                   className={
                    inputValues.version == null || inputValues.version == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-version"
                >Versión</label>
                <input
                  type="text"
                  name="version"
                  value={inputValues.version}
                  onChange={handleInputChange}
                  pattern="[^<>]+"
                />
              </div>
              <div className="select-container" id="input-big-container campo">
                <label
                   className={
                    inputValues.ano == null || inputValues.ano == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-ano"
                >Año</label>
                <div className="flecha-select-container">
                  <i></i>
                </div>
                <select
                  name="ano"
                  value={inputValues.ano}
                  onChange={handleInputChange}
                >
                  <option value=""></option>
                  {anos.map((ano) => (
                    <option key={ano} value={ano}>
                      {ano}
                    </option>
                  ))}
                </select>
              </div>
          </div>
          <p id="errorMessage"></p>
          <button 
            type="submit"
            className="submit-button"
          >
            Enviar info
          </button>
        </form>
      </div>
    </Container>
  );
};
