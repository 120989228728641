import React, { Component } from "react";
import Container from "./styled";
import Desplegable from "./desplegable";
import Animacion from "./animacion";
import { Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal";

export default class Referidos extends Component {
  // Estado que determina si esta abierta o no el desplegable
  state = {
    isActiveUno: false,
    isActiveDos: false,
  };

  // Toggle para cambiar el estado cuando se abre o se cierra cada una de los desplegables
  handleToggleUno = () => {
    this.setState({
      isActiveUno: !this.state.isActiveUno,
      isActiveDos: false,
    });
  };

  handleToggleDos = () => {
    this.setState({
      isActiveUno: false,
      isActiveDos: !this.state.isActiveDos,
    });
  };

  render() {
    return (
      <Container>
        <div id="max-width-container">
          {/* Tag Container */}
          <Fade delay={100} duration={800} className="tag-container">
            {/* Tag */}
            <div>
              <p>Referidos</p>
            </div>
          </Fade>
          {/* Titulo */}
          <Fade delay={100} duration={800}>
            <h3>
              ¡Aprovechá nuestro <span>programa de referidos</span> y ganá!
            </h3>
          </Fade>
          <Fade delay={100} duration={800} triggerOnce={true}>
            <p id="texto-titulo">
            ¡Ganás por cada contacto que refieras y contrate un seguro con la empresa!
            </p>
          </Fade>
          {/* Información Container */}
          <div id="informacion-referidos-container">
            <div id="imagen-container">
              <Fade
                delay={100}
                duration={600}
              >
                <img src="/images/home/referidos/referidos.webp"></img>
              </Fade>
            </div>
            <div id="texto-container">
              <Fade
                delay={100}
                duration={600}
                className={
                  this.state.isActiveDos == true
                    ? "desplegable-container-abierto"
                    : "desplegable-container"
                }
              >
                <Desplegable
                  clickFunction={this.handleToggleDos}
                  isActive={this.state.isActiveDos}
                >
                  <span>$2.000</span> por cada referido que contrate un seguro de bici.
                </Desplegable>

                <Desplegable
                  clickFunction={this.handleToggleDos}
                  isActive={this.state.isActiveDos}
                >
                  <span>$4.000</span> por cada referido que contrate un seguro de moto.
                </Desplegable>

                <Desplegable
                  clickFunction={this.handleToggleDos}
                  isActive={this.state.isActiveDos}
                >
                  <span>$7.000</span> por cada referido que contrate un seguro de auto.
                </Desplegable>

                <Desplegable
                  clickFunction={this.handleToggleDos}
                  isActive={this.state.isActiveDos}
                >
                  <span>$7.000</span> por cada referido que contrate un seguro de camión con cobertura de Responsabilidad Civil.
                </Desplegable>

                <Desplegable
                  clickFunction={this.handleToggleDos}
                  isActive={this.state.isActiveDos}
                >
                  <span>$14.000 </span> por cada referido que contrate un seguro de camión con coberturas más amplias que Responsabilidad Civil.
                </Desplegable>

                <Fade delay={100} duration={800}>
                  <Link to="https://api.whatsapp.com/send?phone=5491162255399&atext=Hola%2C%20estoy%20interesado%20en%20contratar%20un%20seguro" id="referir-boton">
                    Quiero referir
                  </Link>
                </Fade>


              </Fade>
            </div>

          </div>

        </div>
      </Container>
    );
  }
}
