import React from "react";
import Container from "./styled";
import { ConversationBubble } from "./Bubble/BlubbleContainer"; 

export default function Cubrimos() {


  return (
    <Container>
      {/* Coberturas Container */}
      <div id="coberturas-container" className="opacidad08" >
        {/* Titulo */}
        <h3>
          ¡Hay <span>muchas coberturas</span> para vos y tu auto!
        </h3>
        
        <div id="columnas-container">
          {/* Columna 1 */}
          <div className="columna">
            {/* Tag */}
            
              <ConversationBubble 
                title='Robo' 
                content='Indemnización al asegurado en caso de robo y/o hurto total/parcial de la unidad asegurada, hasta la suma asegurada establecida en la póliza.'
              />

          <div className="line-break"></div>
          {/* Tag */}
          <ConversationBubble 
              title='Incendio' 
              content='Quedan cubiertos los daños parciales/totales que pueda sufrir el vehículo como consecuencia de incendio, hasta la suma asegurada establecida en la póliza.'
          />  
          {/* Tag */}
          <ConversationBubble 
              title='Granizo' 
              content='La cobertura de granizo es sin franquicia y se cubre hasta la suma asegurada del vehículo.'
              clase={"right-bubble"}
          />
        </div>

        {/* Columna 2 */}
        <div className="columna">
          <div className="columna">
            {/* Tag */}
            <ConversationBubble 
              title='Cerraduras' 
              content='Cobertura de cerraduras sin límite de eventos ni suma asegurada con límite de $20.000.'
            />
            {/* Tag */}
            <ConversationBubble 
              title='Resp. Civil' 
              content='Daños ocasionados por el asegurado y/o conductor del vehículo asegurado a terceras personas, transportadas o no, y/o los daños materiales producidos a bienes de terceras personas. En cada caso consultar condiciones particulares.'
            />
          </div>

        </div>

        {/* Columna 3 */}
        <div className="columna">
          {/* Tag */}
          <ConversationBubble 
              title='Auxilio y Asistencia' 
              content='Asistencia a las personas y los vehículos, se brindan ante averías mecánicas o siniestro.'
              clase='tag-completo'
          />
          <ConversationBubble 
              title='Aux. y Asist.' 
              content='Asistencia a las personas y los vehículos, se brindan ante averías mecánicas o siniestro.'
              clase='tag-resumen'
          />

          {/* Tag */}
          <ConversationBubble 
              title='Cristales' 
              content='Cobertura de cristales sin límite de eventos ni suma asegurada con límite de $20.000.'
          />
          {/* Tag */}
          <ConversationBubble 
              title='Daños Parciales' 
              content='Sin límite de eventos, hasta agotar la suma asegurada de $250.000.'
              clase='tag-completo'
          />
          <ConversationBubble 
              title='Daños Parc.' 
              content='Sin límite de eventos, hasta agotar la suma asegurada de $250.000.'
              clase='tag-resumen'
          />
        </div>
        </div>
      </div>

      
    </Container>
  );
}
