import * as React from "react";
import Container from "./styled";
import { BsArrowLeft } from "react-icons/bs";
import { Link as Boton } from "react-router-dom";

export default function Titulo({ titulo, tituloSpan }) {
  return (
    <Container>
      {/* Max Width Container */}
      <div id="max-width-container">
        {/* Botón Atras */}
        <Boton to="/" className="opacidad03">
          <span>
            <BsArrowLeft />
          </span>
          Inicio
        </Boton>
        {/* Tag y Fecha Container */}
        <div id="tag-fecha-container" className="opacidad03">
          {/* Tag */}
          <div id="tag">
            <p>Promo</p>
          </div>
          {/* Fecha */}
          <p id="fecha">¡Referí a tus amigos!</p>
        </div>
        {/* Titulo */}
        <h1 className="opacidad03">
          <span>{tituloSpan}</span>
          {titulo}
        </h1>
        {/* Edificios */}
        <div id="edificios-container" className="opacidad03">
          <img
            alt="Edificios del logo de Gestión y Servicios"
            src="/images/institucional/edificios.svg"
            id="edificios"
          ></img>
        </div>
      </div>
    </Container>
  );
}
