import React, { useState } from "react";
import { FaFacebookF } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import ReferidosHeader from "./referidos/index";
import Container from "./styled";
import { useNavigate, Link } from "react-router-dom";
import { Logos } from "./logos";

export default function Header({
  link,
  vehiculo,
  isReferidosHeaderOpen,
  toggleReferidosHeader,
}) {
  return (
    <Container name="header">
      {isReferidosHeaderOpen == true && (
        <ReferidosHeader
          toggleReferidosHeader={toggleReferidosHeader}
        ></ReferidosHeader>
      )}
      <div id="header-container">
        <div id="logo-data-container">
          {/* Logo */}
          <div className="logo">
            <Link to={link ? link : "/"}>
              <Logos vehiculo={vehiculo} />
            </Link>
          </div>
            
          {/* Numeros de telefono y redes Container */}
          <div id="numeros-redes-container">
            {/* Números de telefono */}
            <a className="boton-telefono" href="tel:08102202373">
              0810-220-2373
            </a>
            <a className="boton-telefono" href="tel:1152632581">
              (011) 5263-2581
            </a>
            {/* Redes */}
            <div id="redes">
              {/* Instagram */}
              <a
                href="https://www.instagram.com/seguroweb/?hl=es"
                target="_blank"
              >
                <BsInstagram />
              </a>
              {/* Facebook */}
              <a href="https://www.facebook.com/SeguroWeb/" target="_blank">
                <FaFacebookF />
              </a>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
