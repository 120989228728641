import React from "react";
import Container from "./styled";
import { useLocation, Link } from 'react-router-dom'
import url from '../../../utils/url'

export default function TextButton() {

  // const location = useLocation()
  // const locationURL = location.pathname.split("/")
  // let homeLocationPath;
  
  // switch(url === "https://www.segurobici.com.ar" ? locationURL[3] : locationURL[1]){
  //   case 'bicicleterias': 
  //     homeLocationPath = "/bicicleterias"
  //     break
  //   default :
  //     homeLocationPath = "/"
  //     break
  // }

  return (
    <Container>
      {/* Title */}
      <h1 className="opacidad12">No pudimos encontrar la ruta solicitada</h1>
      {/* Text */}
      <p className="opacidad12">
        Si pensas que es un error, por favor contactanos.
      </p>
      {/* Button */}
      <Link to={"/"} className="opacidad12">
        Inicio
      </Link>
    </Container>
  );
}
