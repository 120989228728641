import Tippy from "@tippyjs/react";
import React, { useState } from "react";
import { BsCheckLg } from "react-icons/bs";
import styled from "styled-components";

const BubbleContainer = styled.div`
    height: 156px;
    position: absolute;
    margin-bottom: 240px;
    background-color: #fff;
    border: 1px solid #E9E9E9;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 2px 0px 2px rgba(0, 0, 0, 0.1);

`;
export const ConversationBubble = ({ title, content, clase }) => {

    return (
    <Tippy 
        content={content} 
        animation="scale"
        followCursor={true}
        theme="light"
    >
        <div className={`tag ${clase}`}>
            <p>
            <span>
                <BsCheckLg />
            </span>
            {title}
            </p>
        </div>
    </Tippy>
        
    );
};