import styled from "styled-components";

const Container = styled.div`
  width: 100%;

  // Header Bar Open and Close
  .header-abierto,
  .header-cerrado {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    padding: 24px 32px 24px 32px;
    background-color: var(--blanco);
    border: 0;
    border-radius: 16px;
    cursor: pointer;
    transition: 0.15s ease;
    overflow: hidden;
  }

  // Header Bar Title
  .header-abierto h4,
  .header-cerrado h4 {
    color: var(--azul20);
    font-weight: 600;
    margin: 0px 24px 0px 0px;
    text-align: left;
    transition: 0.2s all;
  }

  // Hader Abierto
  .header-abierto h4 {
    color: var(--verde50);
  }

  // Hover
  .header-cerrado:hover > h4 {
    color: var(--verde50) !important;
  }

  // Header Bar Hover Image
  .header-abierto img {
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  .header-cerrado img {
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  // Text Container
  .texto {
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    align-content: flex-start;
  }

  // Text
  .texto p {
    width: 100%;
    text-align: left;
    margin: 0px 0px 0px 0px;
    padding: 0px 32px 24px 32px;
    line-height: 27px;
  }

  // Button in text
  .texto a {
    color: var(--azul20) !important;
    cursor: pointer;
    text-decoration: none;
  }

  // Button Icon
  .texto a span {
    margin-left: 5px;
    margin-right: 5px;
  }

  // Tiempo de transicion cuando se abre y se cierra
  .ReactCollapse--collapse {
    transition: height 400ms;
  }
`;

export default Container;
