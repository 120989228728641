import styled from "styled-components";
import { breakpoints } from "../../constants/media";

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  align-content: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  z-index: 100;

  // Header Container
  #header-container {
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    border-bottom: 1px solid var(--grisClaro);
    background: rgba(255, 255, 255, 0.65);
    backdrop-filter: saturate(180%) blur(20px);
  }

  #logo-data-container {
    width: 100%;
    max-width: 1270px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
  }

  .logo {
    transition: 0.2s all;
  }

  .logo a {
    cursor: pointer;
  }

  .logo a img {
    display: block;
    width: 224px;
    max-height: 32px;
  }

  .logo:hover {
    transform: scale(0.97);
  }

  #numeros-redes-container {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
  }

  #numeros-redes-container p {
    margin-left: 24px;
    color: var(--negro);
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
  }

  .boton-telefono {
    margin: 2px 0px 0px 24px;
    color: var(--negro);
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    text-decoration: none;
  }

  #redes {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    margin: 0px 0px 0px 24px;
  }

  #redes a {
    margin: 5px 0px 0px 16px;
    font-size: 17px;
    color: var(--azul60);
    transition: 0.2s all;
  }

  #redes a:hover {
    color: var(--azul20);
  }

  // 1710px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1710}) {
    #logo-data-container {
      max-width: 1000px;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    #logo-data-container {
      max-width: 680px;
    }

    #numeros-redes-container {
      width: 80%;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    #logo-data-container {
      justify-content: center;
    }

    #numeros-redes-container {
      display: none;
    }
  }
`;

export default Container;
